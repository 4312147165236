var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('c-table',{attrs:{"url":'PurchaseWebService/get_merchant_list',"per-page":10,"fields":_vm.columns,"columns":[
          'code',
          'name',
          'register_number',
          'start_date',
          'end_date',
          'create_date',
        ],"filename":'Merchant List',"orderDirection":"ASC","orderBy":"code","hover":"","bordered":"","fixed":"","caption":"","edit_has":true,"edit_route":'admin.purchase.merchant.edit',"edit_params":{ id: 0 }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }